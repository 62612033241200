import React, { useContext } from 'react';
import * as MUI from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GlobalStateContext } from 'store/GlobalStateContext';
import * as SC from './Alert.sc';

export const Alert = ({ data }) => {
  // eslint-disable-next-line no-unused-vars
  const [globalState, dispatchGlobalState] = useContext(GlobalStateContext);
  const {
    text = 'Example of alert',
    isVisible = false,
    type = 'warning',
  } = data;

  const handleClose = () => {
    dispatchGlobalState({ type: 'HIDE_ALERT' });
  };

  return (
    <SC.Container style={{ display: isVisible ? 'flex' : 'none' }}>
      <MUI.Alert severity={type}>
        <SC.AlertTitle>
          <span>
            {type.slice(0, 1).toUpperCase()}
            {type.slice(1).toLowerCase()}
          </span>
          <SC.IconHolder onClick={handleClose}>
            <CloseIcon />
          </SC.IconHolder>
        </SC.AlertTitle>
        {text}
      </MUI.Alert>
    </SC.Container>
  );
};
