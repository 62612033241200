import styled from '@emotion/styled';
import * as MUI from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  top: 20px;
  right: 20px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1000;
`;

export const AlertTitle = styled(MUI.AlertTitle)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-flex-start;
  width: 100%;
`;

export const IconHolder = styled.div`
  color: gray;
  cursor: pointer;
  & :hover {
    color: black;
  }
`;
