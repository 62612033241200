/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import AuthGate from './components/AuthGate/AuthGate';
import { StylesAndThemeProvider } from './styles/StylesAndThemeProvider';
import { GlobalStateContextProvider } from './store/GlobalStateContextProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as UI from './UI';
import { config } from './config/config';

ReactDOM.render(
  <React.StrictMode>
    <StylesAndThemeProvider>
      <GlobalStateContextProvider>
        <BrowserRouter>
          <UI.AlertConfirmSpinnerProvider />
          <AuthGate />
        </BrowserRouter>
      </GlobalStateContextProvider>
    </StylesAndThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          if (
            confirm(
              `A new version of this web application is available.\nLoad next version after ${config.version}?`
            )
          ) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
