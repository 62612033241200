/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { useState, useRef, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordChangeForAdmin } from 'models/yupSchemas';
import * as auth from 'api/auth';

export const usePasswordChanger = () => {
  const history = useHistory();
  const [user, setUser] = useState();
  const [isUpdating, setIsUpdating] = useState(true);
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { globalState, dispatchGlobalState, showSpinner, hideSpinner } =
    useGlobalStateUtils();

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(passwordChangeForAdmin),
  });

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code = query.get('code');

  const password = useRef();
  password.current = watch('password', '');

  const onSubmit = async ({ password }) => {
    setIsUpdating(true);
    showSpinner();

    const result = await auth.saveNewPasswordByUserId({
      newPassword: password,
      userId: user?.id,
    });

    if (result?.error) {
      dispatchGlobalState({ type: 'SHOW_ERROR', payload: result.error });
    }

    if (result?.value?.message === 'Password was changed') {
      setIsPasswordUpdated(true);
    }

    hideSpinner();
    setIsUpdating(false);
  };

  useEffect(() => {
    document.title = `SNFY - Reset Password`;

    return () => {
      document.title = 'SNFY';
    };
  });

  useEffect(async () => {
    showSpinner();
    const result = await auth.checkPasswordChangeCode({
      code,
    });
    if (result?.error) {
      dispatchGlobalState({ type: 'SHOW_ERROR', payload: result.error });
    }

    if (result?.value) {
      setUser(result.value?.user);
    }

    hideSpinner();
    setIsUpdating(false);
  }, []);

  return {
    isPasswordVisible,
    isUpdating,
    isPasswordUpdated,
    setIsPasswordVisible,
    handleSubmit,
    onSubmit,
    control,
    password,
    isDirty,
    user,
    error: globalState.error,
  };
};
