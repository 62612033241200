import {
  USERNAME_MAX_LENGTH,
  ADDRESS_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from './constants';

export const required = 'is required';

export const phoneNumberPattern = {
  value: /^([+]?[0-9\s()-])+$/i,
  message: 'Use numbers only',
};

export const roleValidation = {
  required,
};

export const genderValidation = {
  required,
};

export const medicalInsuranceIdValidation = {
  required,
};

export const dateOfBirthValidation = {
  required,
  validate: value => {
    const str = value.toString();
    const year = str.substr(11, 4);

    if (str !== 'Invalid Date') {
      if (year > 999) {
        return true;
      }
      return 'In mm/dd/yyyy format';
    }
    return 'Invalid Date';
  },
};

export const fullNameValidation = {
  required,
  pattern: {
    value: /^[A-Za-z0-9\s]+$/i,
    message: 'Use letters and numbers only',
  },
  maxLength: {
    value: USERNAME_MAX_LENGTH,
    message: `Max length is ${USERNAME_MAX_LENGTH}`,
  },
};

export const firstNameValidation = {
  ...fullNameValidation,
  required,
};

export const lastNameValidation = {
  ...fullNameValidation,
  required,
};

export const addressValidation = {
  required,
  pattern: {
    value: /^[A-Za-z0-9\s,.]+$/i,
    message: 'Use letters and numbers only',
  },
  maxLength: {
    value: ADDRESS_MAX_LENGTH,
    message: `Max length is ${ADDRESS_MAX_LENGTH}`,
  },
};

/*
/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
*/

export const phoneNumberValidation = {
  required,
  pattern: phoneNumberPattern,
  maxLength: {
    value: PHONE_MAX_LENGTH,
    message: `Max length is ${PHONE_MAX_LENGTH}`,
  },
};

export const patientphoneNumberValidation = {
  pattern: phoneNumberPattern,
  maxLength: {
    value: PHONE_MAX_LENGTH,
    message: `Max length is ${PHONE_MAX_LENGTH}`,
  },
};

export const emailPattern = {
  // value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  value: /^\S+@\S+$/i,
  message: 'Invalid email address',
};

export const emailValidation = {
  required,
  pattern: emailPattern,
};

export const patientEmailValidation = {
  pattern: emailPattern,
};

export const passwordValidation = {
  required,
  validate: {
    minLength: value =>
      value.length >= PASSWORD_MIN_LENGTH ||
      `Min length is ${PASSWORD_MIN_LENGTH}`,
    maxLength: value =>
      value.length <= PASSWORD_MAX_LENGTH ||
      `Max length is ${PASSWORD_MAX_LENGTH}`,
    noWhiteSpaces: value =>
      !!value.match(RegExp('^[^\\s]*$')) || 'need no whitespaces',
    oneLowercase: value =>
      !!value.match(RegExp('(.*[a-z].*)')) ||
      'need one lowercase character at least',
    oneUppercase: value =>
      !!value.match(RegExp('(.*[A-Z].*)')) ||
      'need one uppercase character at least',
    oneNumber: value =>
      !!value.match(RegExp('(.*\\d.*)')) ||
      'need one number character at least',
    oneSpecialChar: value =>
      !!value.match(RegExp('[!@#$%^&*(),.?":{}|<>]')) ||
      'need one special character at least',
  },
};

export const getPasswordConfirmValidation = password => ({
  validate: value => value === password || 'Passwords must match',
});

export const getProcedureSideValidation = getValues => ({
  validate: value =>
    ((!!getValues()?.procedure ||
      !!getValues()?.dateOfPastSurgery ||
      !!getValues()?.dateOfSurgery) &&
      !!value) ||
    (!getValues()?.procedure &&
      !getValues()?.dateOfPastSurgery &&
      !getValues()?.dateOfSurgery &&
      !value) ||
    'Side is required',
});

export const statusValidation = {
  required,
};

export const locationListValidation = {
  required,
};
