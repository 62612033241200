import styled from '@emotion/styled';
import * as MUI from '@mui/material';

export const Toolbar = styled(MUI.Toolbar)`
  font-size: 18px;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Version = styled.span`
  color: gray;
`;
