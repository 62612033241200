/* eslint-disable no-console */
// =====================================
// Define your default global state here
// =====================================

export const defaultGlobalState = {
  user: {
    value: {}, // use initial value to disable autoloading
  },

  // example of simple object
  counter: 0,

  alert: {
    type: 'warning',
    text: 'Please provide alert text!',
    isVisible: false,
  },

  error: '',

  confirm: {
    title: 'Confirmation',
    text: 'Please provide text for this confirm',
    isVisible: false,
    yes: () => console.log('Set callback for YES answer.'),
    no: () => console.log('Set callback for NO answer.'),
    contentRender: null,
  },

  spinner: false,

  isDataChanged: false, // signals if form data is changed but is not saved

  currentTab: 0,

  // example of autoloadable API props
  users: {
    // value: undefined, // use undefined for autoloading
    // value: [], // use initial value to disable autoloading
  },
};

// ======================================
