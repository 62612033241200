import React from 'react';
import * as MUI from '@mui/material';
import * as SC from './GlobalSpinner.sc';

export const GlobalSpinner = () => {
  return (
    <SC.Container>
      <MUI.CircularProgress />
    </SC.Container>
  );
};
