import React from 'react';
import * as MUI from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as UI from 'UI';

import { FormTextField } from 'UI/FormTextField/FormTextField';

import logo from 'images/logo.svg';
import apple from 'images/apple.svg';
import done from 'images/icon_done.svg';

import { usePasswordChanger } from './usePasswordChanger';
import * as SC from './PasswordChanger.sc';

export const PasswordChanger = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    isPasswordVisible,
    isUpdating,
    isPasswordUpdated,
    setIsPasswordVisible,
    isDirty,
    user,
    error,
  } = usePasswordChanger();

  return (
    <SC.Overlay>
      <SC.Container>
        <img src={logo} alt="logo" />
        <SC.Slogan>The revolution in IoT of food industry</SC.Slogan>
        {error && (
          <>
            <SC.ErrorMessage>
              <UI.EmptySpace />
              <MUI.Typography fontSize={46}>
                <ErrorIcon fontSize="inherit" />
              </MUI.Typography>
              <br />
              Oops, something went wrong.
            </SC.ErrorMessage>
            <SC.Content>
              <SC.Buttons>
                <MUI.Link href="/login" style={{ textDecoration: 'none' }}>
                  <SC.Button variant="contained" color="primary" size="large">
                    Back to login page
                  </SC.Button>
                </MUI.Link>
              </SC.Buttons>
            </SC.Content>
          </>
        )}
        {!error && isUpdating && (
          <SC.Message>
            <UI.Spinner />
          </SC.Message>
        )}
        {!error && !isUpdating && isPasswordUpdated && (
          <>
            <SC.Message>
              <img src={done} alt="Done sign" />
              <br />
              Password changed successfully
            </SC.Message>
            <SC.ResultMessage>
              Now you can log in to your personal account using the SNFY mobile
              app
            </SC.ResultMessage>
            <SC.Content>
              <SC.Buttons>
                <MUI.Link
                  href="https://www.apple.com/ua/search/SNFY?src=globalnav"
                  style={{ textDecoration: 'none' }}
                >
                  <SC.Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={!isDirty}
                  >
                    <img src={apple} alt="Apple logo" />
                  </SC.Button>
                </MUI.Link>
              </SC.Buttons>
            </SC.Content>
          </>
        )}
        {!error && !isUpdating && !isPasswordUpdated && (
          <>
            <SC.Message>
              Change the password for <SC.Email>{user?.username}</SC.Email>
            </SC.Message>
            <SC.Content>
              <SC.FlexRow style={{ width: '100%', alignItems: 'flex-start' }}>
                <SC.Form onSubmit={handleSubmit(onSubmit)}>
                  <SC.FlexRow>
                    <FormTextField
                      label="New Password"
                      name="password"
                      control={control}
                      autoComplete="off"
                      type={isPasswordVisible ? 'text' : 'password'}
                      variant="filled"
                      disabled={isUpdating}
                    />
                    <MUI.Button
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible && <VisibilityOffIcon />}
                      {!isPasswordVisible && <VisibilityIcon />}
                    </MUI.Button>
                  </SC.FlexRow>
                  <UI.EmptySpace />
                  <FormTextField
                    label="Confirm Password"
                    name="password_repeat"
                    control={control}
                    autoComplete="off"
                    type={isPasswordVisible ? 'text' : 'password'}
                    variant="filled"
                    disabled={isUpdating}
                  />
                </SC.Form>
              </SC.FlexRow>
              <SC.Buttons>
                {isUpdating ? (
                  <UI.Spinner />
                ) : (
                  <SC.Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty}
                  >
                    Reset password
                  </SC.Button>
                )}
              </SC.Buttons>
            </SC.Content>
          </>
        )}
      </SC.Container>
    </SC.Overlay>
  );
};
