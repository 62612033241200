import { httpService } from './httpService';
import { localStorageNameEnum } from '../models/constants';

export const login = async ({ username, password }) =>
  httpService('/dash/pass-setup', {
    method: 'POST',
    data: {
      username,
      password,
    },
  });

export const restoreAuthByToken = async () =>
  httpService(
    '/auth/refresh',
    {
      method: 'GET',
    },
    localStorage.getItem(localStorageNameEnum.REFRESH_AUTH_TOKEN)
  );

export const requestPasswordRecoveryLink = async ({ email }) =>
  httpService('/user/request-reset', {
    method: 'POST',
    data: {
      email,
    },
  });

export const getRecoveryAuthByToken = async ({ token }) =>
  httpService(`/user/forgot-password?token=${token}`, {
    method: 'GET',
  });

export const checkPasswordChangeCode = async ({ code }) =>
  httpService(`/dash/pass-code-verify?code=${code}`, {
    method: 'GET',
  });

export const saveNewPasswordByUserId = async ({ newPassword, userId }) =>
  httpService('/dash/pass-setup', {
    method: 'POST',
    data: {
      newPassword,
      userId,
    },
  });
