import React from 'react';
import * as MUI from '@mui/material';
import * as SC from './ErrorBox.sc';

export const ErrorBox = ({ text, width = '100%' }) => {
  return text ? (
    <MUI.Alert variant="filled" severity="error" style={{ width, margin: '0' }}>
      <SC.Container>{text}</SC.Container>
    </MUI.Alert>
  ) : null;
};
