/* eslint-disable no-console */

import React from 'react';
import * as MUI from '@mui/material';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './Confirm.sc';

export const Confirm = withGlobalStateUtils(
  ({
    title = 'Title',
    text = 'content',
    yes = console.log,
    no = console.log,
    contentRender,
    isVisible = true,
    hideConfirm,
  }) => {
    const handleYes = () => {
      yes();
      hideConfirm();
    };

    const handleNo = () => {
      no();
      hideConfirm();
    };

    return (
      <SC.Container>
        <MUI.Dialog open={isVisible}>
          <MUI.DialogTitle>{title}</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.DialogContentText>{text}</MUI.DialogContentText>
            <MUI.DialogContent>{contentRender || ''}</MUI.DialogContent>
          </MUI.DialogContent>
          <SC.DialogActions>
            <MUI.Button variant="contained" color="primary" onClick={handleNo}>
              Cancel
            </MUI.Button>
            <MUI.Button
              variant="contained"
              color="secondary"
              onClick={handleYes}
            >
              Confirm
            </MUI.Button>
          </SC.DialogActions>
        </MUI.Dialog>
      </SC.Container>
    );
  }
);
