import styled from '@emotion/styled';
import * as MUI from '@mui/material';
import * as SC from 'SC/App.sc';

export { FlexRow, FlexColumn, Form } from 'SC/App.sc';

export const Overlay = styled(SC.Overlay)`
  background-color: #fff;
`;

export const Container = styled(SC.FlexColumn)`
  margin: auto;
  margin-top: 73px;
  max-width: 597px;
`;

export const Content = styled(SC.FlexColumn)`
  margin-top: 71px;
  justify-content: space-between;
  align-items: stretch;
  width: 95%;
  max-width: 472px;
`;

export const Slogan = styled.div`
  margin-top: 15px;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #616161;
`;

export const Message = styled.div`
  margin-top: 89px;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

export const Email = styled.span`
  font-style: italic;
`;

export const ResultMessage = styled.div`
  margin-top: 29px;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
`;

export const ErrorMessage = styled(ResultMessage)`
  color: red;
`;

export const Buttons = styled(SC.Buttons)`
  margin-top: 60px;
  justify-content: center;
`;

export const Button = styled(MUI.Button)`
  width: 341px;
  height: 65px;
  font-size: 22px;
`;
