export const AUTOLOGOUT_DELAY_MIN = 15;
export const MESSAGES_REFRESH_INTERVAL_MS = 5000;
export const PROCEDURES_REFRESH_INTERVAL_MS = 15000;
export const PATIENTS_REFRESH_INTERVAL_MS = 45000;
export const LOCATIONS_REFRESH_INTERVAL_MS = 60000;

export const DEBOUNCE_TIME_FOR_ALERT_MS = 2000;

export const USERNAME_MAX_LENGTH = 250;
export const ADDRESS_MAX_LENGTH = 250;
export const PHONE_MAX_LENGTH = 100;
export const PASSWORD_MIN_LENGTH = 3;
export const PASSWORD_MAX_LENGTH = 12;

export const RoleEnum = {
  MASTER_ADMIN: 'MASTER_ADMIN',
  ADMIN: 'ADMIN',
  SURGEON: 'SURGEON',
  SURGICAL_ASSISTANT: 'SURGICAL_ASSISTANT',
  RADIOLOGIST: 'RADIOLOGIST',
  IMAGE_ENGINEER: 'IMAGE_ENGINEER',
  PASSWORD_CHANGE: 'CHANGE_PASSWORD',
};

export const RoleLabelEnum = {
  MASTER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  SURGEON: 'Surgeon',
  SURGICAL_ASSISTANT: 'Surgeon Assistant',
  RADIOLOGIST: 'Radiologist',
  IMAGE_ENGINEER: 'Engineer',
};

export const UserStatusEnum = {
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  PENDING: 'PENDING',
};

export const UserStatusLabelEnum = {
  ACTIVE: 'Active',
  BLOCKED: 'Blocked',
  PENDING: 'Pending',
};

export const localStorageNameEnum = {
  REFRESH_AUTH_TOKEN: 'REFRESH_AUTH_TOKEN',
  REFRESH_TOKEN_EXPIRES: 'REFRESH_TOKEN_EXPIRES',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_TOKEN_EXPIRES: 'TOKEN_EXPIRES',
};
