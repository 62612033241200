/* eslint-disable no-console */
import { useContext } from 'react';
import { GlobalStateContext } from '../store/GlobalStateContext';

export const useGlobalStateUtils = () => {
  const [globalState, dispatchGlobalState] = useContext(GlobalStateContext);
  const { error, spinner, confirm } = globalState;

  /*
   * Shows alert on user screen
   * @param {{ type: 'warning'|'error'|'success; text: string  }}
   */
  const showAlert = ({
    type = 'warning',
    text = `Please provide text for this alert`,
  }) =>
    dispatchGlobalState({
      type: 'SHOW_ALERT',
      payload: {
        type,
        text,
      },
    });
  const hideAlert = () => dispatchGlobalState({ type: 'HIDE_ALERT' });

  const showError = text =>
    dispatchGlobalState({ type: 'SHOW_ERROR', payload: text });
  const hideError = () => dispatchGlobalState({ type: 'HIDE_ERROR' });

  const showSpinner = () => dispatchGlobalState({ type: 'SHOW_SPINNER' });
  const hideSpinner = () => dispatchGlobalState({ type: 'HIDE_SPINNER' });

  const showConfirm = ({
    title = 'Confirmation',
    text = `Please provide text for this alert`,
    yes = () => console.log('Set callback for YES answer.'),
    no = () => console.log('Set callback for NO answer.'),
    contentRender = null,
  }) =>
    dispatchGlobalState({
      type: 'SHOW_CONFIRM',
      payload: {
        title,
        text,
        yes,
        no,
        contentRender,
      },
    });
  const hideConfirm = () => dispatchGlobalState({ type: 'HIDE_CONFIRM' });
  return {
    globalState,
    dispatchGlobalState,
    error,
    spinner,
    confirm,
    showError,
    hideError,
    showAlert,
    hideAlert,
    showConfirm,
    hideConfirm,
    showSpinner,
    hideSpinner,
  };
};
