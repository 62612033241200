/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGlobalStateUtils } from 'hooks/useGlobalStateUtils';
import * as MUI from '@mui/material';
import * as UI from 'UI';
import { RoleEnum, localStorageNameEnum } from 'models/constants';
import * as authApi from 'api/auth';
import * as SC from './TokenAuth.sc';

export const TokenAuth = () => {
  const {
    globalState,
    dispatchGlobalState,
    isUpdating,
    showSpinner,
    hideSpinner,
    error,
  } = useGlobalStateUtils();
  const { search } = useLocation();
  const [isLinkWrong, setIsLinkWrong] = useState(false);
  const query = new URLSearchParams(search);
  const token = query.get('token');

  useEffect(async () => {
    showSpinner();
    const result = await authApi.getRecoveryAuthByToken({ token });
    hideSpinner();

    if (result?.error) {
      setIsLinkWrong(true);
      return;
    }

    const preparedData = {
      value: {
        ...result.value,
      },
    };

    dispatchGlobalState({
      type: 'SET_user',
      payload: preparedData,
    });
    // localStorage.removeItem(localStorageNameEnum.REFRESH_AUTH_TOKEN);
    // localStorage.removeItem(localStorageNameEnum.REFRESH_TOKEN_EXPIRES);
    // localStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
    // localStorage.removeItem(localStorageNameEnum.AUTH_TOKEN_EXPIRES);
  }, []);

  if (isLinkWrong)
    return (
      <SC.Overlay>
        <SC.Container>
          <UI.Panel>
            This link is expired
            <UI.EmptySpace />
            <Link to="/login">
              <MUI.Button
                size="large"
                type="button"
                variant="contained"
                color="secondary"
              >
                Back to log in
              </MUI.Button>
            </Link>
          </UI.Panel>
        </SC.Container>
      </SC.Overlay>
    );

  if (isUpdating)
    return (
      <SC.Overlay>
        <SC.Container>
          <UI.Panel />
        </SC.Container>
      </SC.Overlay>
    );

  return (
    <SC.Overlay>
      {isUpdating && (
        <SC.Container>
          <UI.Panel>
            Action denied
            <UI.EmptySpace />
            <Link to="/login">
              <MUI.Button
                size="large"
                type="button"
                variant="contained"
                color="secondary"
              >
                Back to log in
              </MUI.Button>
            </Link>
          </UI.Panel>
        </SC.Container>
      )}
    </SC.Overlay>
  );
};
