/* eslint-disable no-restricted-globals */
import React from 'react';
import { useHistory } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { withGlobalStateUtils } from 'utils/withGlobalStateUtils';
import * as SC from './RowActionsPanel.sc';

export const RowActionsPanel = withGlobalStateUtils(
  ({
    row,
    hideError,
    showConfirm,
    editorUrl,
    handleDeleteAction,
    isDeletable = true,
  }) => {
    if (!editorUrl) {
      throw Error('Set editorUrl prop for RowActionPanel component usage');
    }
    const history = useHistory();

    const handleEdit = () => {
      history.push(`${editorUrl}${row.id}`);
    };

    const handleDelete = async () => {
      hideError();
      const confirmationText = `Delete "${
        row.name || row.fullName || 'row'
      }" with id #${row.id} ?`;
      showConfirm({
        title: 'Confirmation',
        text: confirmationText,
        yes: () => handleDeleteAction(row.id),
      });
    };

    return (
      <SC.Container>
        <SC.EditButton type="button" variant="text" onClick={handleEdit}>
          <MoreVertIcon />
        </SC.EditButton>
        {isDeletable && (
          <SC.DeleteButton type="button" variant="text" onClick={handleDelete}>
            <DeleteForeverIcon />
          </SC.DeleteButton>
        )}
      </SC.Container>
    );
  }
);
