import * as yup from 'yup';
import { PASSWORD_MIN_LENGTH } from './constants';

/* TODO - remove YUP package and this validation scheme */

// this is an example of the yup validation integration with reak hook form

export const passwordChangeForAdmin = yup
  .object({
    password: yup.string().required().min(PASSWORD_MIN_LENGTH), // min password length
    // .max(12) // max password length
    // .matches(RegExp('^[^\\s]*$'), 'need no whitespaces')
    // .matches(RegExp('(.*[a-z].*)'), 'need one lowercase character at least')
    // .matches(RegExp('(.*[A-Z].*)'), 'need one uppercase character at least')
    // .matches(RegExp('(.*\\d.*)'), 'need one number character at least')
    // .matches(
    //   RegExp('[!@#$%^&*(),.?":{}|<>]'),
    //   'need one special character at least'
    // )
    password_repeat: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();
