/* eslint-disable no-console */
/* eslint-disable prefer-template */
import { config } from '../config/config';
import { localStorageNameEnum } from '../models/constants';

/*    
    This service returns only two variants of the result:
    
      1) In case of successful result: 
      {
        value: <returned value>,
      }

      2) When something goes wrong: 
      {
        error: <error message>,
      }
 */

export const httpService = async (url, options, token) => {
  const storedToken = localStorage.getItem(localStorageNameEnum.AUTH_TOKEN);
  const { data } = options;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token !== undefined) {
    headers.Authorization = `Bearer ${token}`;
  } else if (storedToken) {
    headers.Authorization = `Bearer ${storedToken}`;
  }

  try {
    const response = await fetch(`${config.API_URL}${url}`, {
      ...options,
      headers,
      body: JSON.stringify(data),
    });

    const isJson =
      response?.headers?.get('Content-Type') === 'application/json';

    if (!response.ok) {
      console.log(`=== Raw response: `, response);

      if (isJson) {
        try {
          const result = await response.json();
          throw new Error(result?.message || result?.error || '');
        } catch (error) {
          throw new Error(error.message);
        }
      }
      throw new Error(response);
    }

    if (isJson) {
      const result = await response.json();
      if (result?.error) {
        throw new Error(result.error.message);
      }
      return {
        value: result,
      };
    }

    return {
      value: response,
    };
  } catch (error) {
    return {
      error: String(error.message).replace(/Error:/g, ''),
      // error: 'Network error',
    };
  }
};
