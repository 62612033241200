/* eslint-disable no-console */
import { useContext, useEffect } from 'react';
import { GlobalStateContext } from './GlobalStateContext';
import { syncApiData, getApiDataState } from '../utils/apiDataUtils';

export const useGlobalStateApiProperty = ({ key, apiCallFn }) => {
  if (!key || !apiCallFn) {
    throw new Error('Add key and apiCallFn to useGlobalStateApiProperty');
  }

  const [globalState, dispatchGlobalState] = useContext(GlobalStateContext);
  const stateValue = globalState[key];

  const setStateValue = payload => {
    dispatchGlobalState({ type: `SET_${key}`, payload });
  };

  useEffect(() => {
    syncApiData(apiCallFn, stateValue, setStateValue);
  }, [stateValue]);

  return [stateValue, setStateValue, getApiDataState(stateValue)];
};
