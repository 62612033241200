import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as SC from './Panel.sc';

export const Panel = ({ title, children, handleCloseIcon = false }) => {
  return (
    <SC.Container elevation={5}>
      {!!title && (
        <SC.Header>
          <SC.Title>
            {title}
            {handleCloseIcon && (
              <CloseIcon
                sx={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                  '&:hover': {
                    color: 'red',
                    border: 'thin solid red',
                  },
                }}
                onClick={handleCloseIcon}
              />
            )}
          </SC.Title>
        </SC.Header>
      )}
      <SC.Content>{children}</SC.Content>
    </SC.Container>
  );
};
