import styled from '@emotion/styled';
import * as SC from 'SC/App.sc';

export const TabContent = styled(SC.FlexColumn)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fefaf4;
  label: tab-content;
`;
