import React from 'react';
import * as MUI from '@mui/material';
import * as SC from './Spinner.sc';

export const Spinner = () => {
  return (
    <SC.Container>
      <MUI.CircularProgress />
    </SC.Container>
  );
};
