/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Login from 'components/Login/Login';

import PasswordRecovery from 'components/PasswordRecovery/PasswordRecovery';
import { PasswordChanger } from 'components/PasswordChanger/PasswordChanger';
import { TokenAuth } from 'components/TokenAuth/TokenAuth';
import { useAuthGate } from './useAuthGate';

const AuthGate = () => {
  const { globalState } = useAuthGate();

  // console.log(`==== globalState: `, globalState);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/forgot_password" component={TokenAuth} />
        <Route path="/password_recovery" component={PasswordRecovery} />
        <Route path="/password_change" component={PasswordChanger} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default AuthGate;
