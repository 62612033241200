import styled from '@emotion/styled';
import * as MUI from '@mui/material';
import * as SC from 'SC/App.sc';

export const Container = styled(MUI.Paper)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  width: 100%;
  border-radius: 20px;
  overflow-y: hidden;
`;

export const Header = styled(SC.FlexRow)`
  flex: 0 0 auto;
  justify-content: flex-start;
  background: #feecd9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 61px;
  padding: 0 20px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

export const Title = styled(SC.FlexRow)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Content = styled(SC.FlexColumn)`
  flex: 1 1 auto;
  padding: 20px;
  justify-content: flex-start;
  align-items: stretch;
  width: calc(100% - 40px);
  overflow-y: auto;
  label: PanelContent;
`;
