import * as packageJSON from '../../package.json';

const { version } = packageJSON.default;

const getConfig = () => {
  const config = {
    version,
  };
  // load config from .env file without REACT_APP_ prefix
  [...Object.keys(process.env)].forEach(key => {
    const prefix = key.substring(0, 10);
    if (prefix === 'REACT_APP_') {
      config[key.substring(10)] = process.env[key];
    }
  });

  return config;
};

export const config = getConfig();
