import styled from '@emotion/styled';
import { Link as LinkN } from 'react-router-dom';
import * as MUI from '@mui/material';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 358px;
  box-sizing: border-box;

  & h6 {
    margin: 10px 0;
  }
`;

export const LoginForm = styled(FlexColumn)`
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  min-height: 220px;
  width: 360px;
  background-color: white;
`;

export const Input = styled(MUI.Input)`
  width: 100%;
`;

export const Version = styled.div`
  color: gray;
  font-size: 10px;
`;

export const Link = styled(LinkN)`
  font-size: 10px;
`;
