import { useContext, useEffect } from 'react';
import { debounce } from 'lodash';
import { GlobalStateContext } from 'store/GlobalStateContext';
import {
  AUTOLOGOUT_DELAY_MIN,
  localStorageNameEnum,
  DEBOUNCE_TIME_FOR_ALERT_MS,
} from 'models/constants';

import * as authApi from 'api/auth';

const TEN_MIN_TO_TOKEN_EXPIRED_IN_MS = 10 * 60 * 1000;

export const useAuthGate = () => {
  const [globalState, dispatchGlobalState] = useContext(GlobalStateContext);
  const { alert, user } = globalState;

  const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  const logoutUser = () => {
    dispatchGlobalState({ type: 'LOGOUT' });
  };

  const restoreAuthByRefreshToken = async () => {
    const storedRefreshToken = localStorage.getItem(
      localStorageNameEnum.REFRESH_AUTH_TOKEN
    );
    if (!storedRefreshToken) return;

    const result = await authApi.restoreAuthByToken(storedRefreshToken);

    if (result?.error) {
      dispatchGlobalState({ type: 'LOGOUT' });
      window.location.reload();
      return;
    }
    dispatchGlobalState({ type: 'SET_user', payload: result });
  };

  const moveLogoutTimer = debounce(
    logoutUser,
    AUTOLOGOUT_DELAY_MIN * 60 * 1000
  );

  useEffect(() => {
    events.forEach(name => {
      document.addEventListener(name, moveLogoutTimer);
    });

    moveLogoutTimer();

    return () => {
      events.forEach(name => {
        document.removeEventListener(name, moveLogoutTimer);
      });
    };
  }, []);

  useEffect(() => {
    if (alert.isVisible) {
      clearTimeout(window.alertTimeout);
      window.alertTimeout = setTimeout(() => {
        dispatchGlobalState({ type: 'HIDE_ALERT' });
      }, DEBOUNCE_TIME_FOR_ALERT_MS);
    }
  }, [alert]);

  useEffect(async () => {
    const token = user?.value?.access?.token;
    const expiresAt = user?.value?.access?.expiresAt;
    const storedRefreshToken = localStorage.getItem(
      localStorageNameEnum.REFRESH_AUTH_TOKEN
    );

    // check if there is stored auth in localStorage and try to restore auth.
    if (!token && storedRefreshToken) {
      restoreAuthByRefreshToken();
    }

    if (token && storedRefreshToken) {
      const interval = expiresAt - Date.now() - TEN_MIN_TO_TOKEN_EXPIRED_IN_MS;

      // start debounced autorefresh for auth
      debounce(() => {
        restoreAuthByRefreshToken();
      }, interval)();
    }
  }, [user]);

  return {
    user,
    globalState,
  };
};
